import axios from 'axios'

import { getFirebaseBackend } from '../../authUtils.js'

export const state = {
    currentUser: localStorage.getItem('authUser'),
    jwt: localStorage.getItem('jwt'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    },
    SET_TOKEN(state, newValue) {
        state.jwt = newValue
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    jwt(state) {
        return state.jwt
    },
    currentUser(state) {
        return state.currentUser
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    async logIn({ commit }, { email: username, password } = {}) {
        const result = await axios.post(`/api/login`, {
            username: username,
            password: password,
        });

        if (result.data.message && result.data.message == 'Invalid credentials') {
            return this.authError = result.data.data;
        }
        
        const currentUser = { username: username, roles: result.data.roles, id: result.data.id };

        commit('SET_CURRENT_USER', currentUser);
        commit('SET_TOKEN', result.data.token);

        localStorage.setItem('authUser', JSON.stringify(currentUser));
        localStorage.setItem('jwt', result.data.token);
        localStorage.setItem('roles', result.data.roles);

        return currentUser
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_CURRENT_USER', null)
        commit('SET_TOKEN', null)
        
        localStorage.removeItem('authUser');
        localStorage.removeItem('jwt');
        localStorage.removeItem('roles');

        return true;
    },

    // register the user
    register({ commit, dispatch, getters }, { username, email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().registerUser(username, email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().forgetPassword(email).then((response) => {
            const message = response.data
            return message
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        const user = getFirebaseBackend().getAuthenticatedUser();
        commit('SET_CURRENT_USER', user)
        return user;
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}
