import axios from "axios";

export const state = {
    loading: false,
    tours: [],
    trucks: [],
    routes: {
        currentPage: 1,
        totalPages: 1,
        totalRoutes: 0,
        routes: [],
    }
};

export const getters = {
    loading: state => state.loading,
    tours: state => state.tours,
    trucks: state => state.trucks,
    routes: state => state.routes,
    pages:  state => {
        const totalPages = state.routes.totalPages;
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
};

export const mutations = {
    setTours(state, newValue) {
        state.tours = newValue
    },
    setTrucks(state, newValue) {
        state.trucks = newValue
    },
    setRoutes(state, newValue) {
        state.routes = newValue
    },
    setLoading(state, newValue) {
        state.loading = newValue
    },
};

export const actions = {
    fetchRoutes({ commit }, payload) {
        commit('setLoading', true)
        const page = payload || 1
        axios.get(`/api/routes/meta?page=${page}&limit=10`).then(res => {
            commit('setRoutes', res.data)
        }).finally(() => {
            commit('setLoading', false)
        })
    },
    fetchTours({ commit }) {
        commit('setLoading', true)
        axios.get('/api/tours').then(res => {
            commit('setTours', res.data.tours)
        }).finally(() => {
            commit('setLoading', false)
        })
    },
    fetchTrucks({ commit }) {
        commit('setLoading', true)
        axios.get('/api/trucks').then(res => {
            commit('setTrucks', res.data.data)
        }).finally(() => {
            commit('setLoading', false)
        })
    },
};
